import { ENCOUNTERS_PATTERN, PATIENT_PATTERN } from "../../util/constant";

import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import EncounterProvider from "../Encounter/useEncounter";

import DashboardProvider, { useDashboard } from "./useDashboard";

import Loading from "../../components/Loading";
import "react-calendar/dist/Calendar.css";

import Calendar from "react-calendar";

// import Notes from;
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

const Encounter = lazy(() => import("../Encounter"));
const Patient = lazy(() => import("./components/Patient"));
const Sidebar = lazy(() => import("./components/Sidebar"));
const Main = lazy(() => import("./components/Main"));
const Patients = lazy(() => import("./components/Patients"));

export default function Dashboard() {
	return (
		<DashboardProvider>
			<Suspense fallback={<Loading />}>
				<UI />
			</Suspense>
			<ToastContainer />
		</DashboardProvider>
	);
}

function UI() {
	const {
		currentUser: { auth, user },
		location: { currentPath },
		set: { title },
	} = useDashboard();

	title(currentPath === "/" ? "Dashboard" : "");

	if (ENCOUNTERS_PATTERN.test(currentPath) === true) {
		return (
			<EncounterProvider>
				<Encounter />
			</EncounterProvider>
		);
	}

	if (currentPath === "/") {
		// title("Dashboard");
		return (
			<Parent>
				<Sidebar />
				<Main />
			</Parent>
		);
	}

	// console.log(PATIENT_PATTERN.test(currentPath));

	if (PATIENT_PATTERN.test(currentPath) === true) {
		return (
			<Parent>
				<Sidebar />
				<Patient />
			</Parent>
		);
	}

	if (currentPath.indexOf("patients") !== -1) {
		return (
			<Parent>
				<Sidebar />
				<Patients />
			</Parent>
		);
	}
}

import { useForm } from "react-hook-form";


const INPUT_CLASS =
	"rounded-[66px] px-4 py-3 bg-page border-[1px] border-stroke border-solid outline-none text-font-400 placeholder:text-[85%] placeholder:text-font-200/85";

function Parent({ children }) {
	const [showCalendar, setShowCalendar] = useState(false);

	const currentToast = useRef(null);

	const [dt, setDT] = useState(new Date());

	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors, isValid, isLoading },
	} = useForm();

	let [email, phno, dob, first, last] = watch([
		"email",
		"phno",
		"dob",
		"first",
		"last",
	]);


	const {
		currentUser: { user },
		location: { navigate },
		set: {
			patient: { visible, dismiss, type: encounterType },
		},
	} = useDashboard();

	// console.log({
	// 	showCalendar,
	// });


	useEffect(() => {
		const handler = (evt) => {
			// console.log(evt);

			evt.preventDefault();

			// console.log(evt.relatedTarget);
			// console.log(Array.from(evt.relatedTarget.classList));
			let g = Array.from(evt.relatedTarget.classList).filter(
				(item) => item.indexOf("react-calendar") !== -1
			);

			// console.log({
			// 	g,
			// });

			if (g.length > 0) {
				setShowCalendar(true);
				return;
			}

			setShowCalendar(false);
		};

		document.getElementById("dob").addEventListener("focusout", handler);

		return () =>
			document
				.getElementById("dob")
				?.removeEventListener("focusout", handler);
	}, []);

	useEffect(() => {
		const handler = (evt) => {
			setShowCalendar(true);
		};

		document.getElementById("dob").addEventListener("focusin", handler);

		return () =>
			document
				.getElementById("dob")
				?.removeEventListener("focusin", handler);
	}, []);

	const onChange = (evt) => {
		// console.log(evt);

		setDT(evt);
		setTimeout(() => {
			setShowCalendar(false);
		}, 50);
	};

	const [ groupDisabled, setGroupDisabled ] = useState(false);

	async function patientFormSubmit() {

		const { phone } = await import("phone");
		const Snackbar = await import("node-snackbar");

		if (typeof first !== "string" || first.trim().length == 0) {
			setError("first", {
				message: "First name is required",
				type: "validate",
			});
			return false;
		}

		if (!(typeof last === "string" && last.trim().length > 0)) {
			setError("last", {
				message: "Last name is required",
				type: "validate",
			});
			return false;
		}

		if (!(typeof dob === "string" && dob.trim().length > 0)) {
			setError("dob", {
				message: "Date of Birth is required",
				type: "validate",
			});
			return false;
		}

		if (!(typeof email === "string" && email.trim().length > 0)) {
			setError("email", {
				message: "Email Address is required",
				type: "validate",
			});
			return false;
		}

		if (typeof phno === "string" && phno.trim().length >= 1) {
			const r = phone(phno, {
				country: undefined,
				validateMobilePrefix: false,
			});
			if (!r.isValid) {
				setError("phno", {
					message:
						"This is not a valid phone number. Please check again.",
					type: "validate",
				});
				return false;
			}
		}

		setGroupDisabled(true);

		const { isPatient } = await import("../../util/util");

		const exists = await isPatient(email);

		if (exists) {
			Snackbar.show({
				text: `There already exists a patient with the email address ${email}. Do you want to overwrite and continue`,
				textColor: "#fff",
				actionText: "Yes",
				actionTextColor: "green",
				secondButtonTextColor: "#fff",
				secondButtonText: "No",
				showSecondButton: !false,
				showAction: true,
				duration: 0,
				customClass: "z-[150]",
				pos: "top-right",
				onActionClick: async () => {
					
					Snackbar.close();
					await createPatient(true, encounterType);

				},
				onSecondButtonClick: () => {
					setGroupDisabled(false);
					Snackbar.close();
				},
			});
		} else {
			await createPatient(false, encounterType);
		}
	}


	const createPatient = async (exists = false, type = "note") => {

		const { processOpts, errorOpts, successOpts } = await import("../../util/toast");
		const { getHeaders, getURL, getDisplayType } = await import("../../util/util");

		currentToast.current = toast(`Please wait while we ${!exists ? "create a new" : "update the"} patient record and a new ${getDisplayType(type)}`, {
			...processOpts
		});


		let headers = await getHeaders();

		let URL = getURL("patients");

		let patientId;

		try {

			const goData = {
				email,
				first,
				last,
				dob: {
					dd: dt.getDate(),
					mm: dt.getMonth() + 1,
					yyyy: dt.getFullYear(),
				},
				phno,
			}

			if(!goData['phno']) {
				delete goData?.phno
			}

			const response = await fetch(URL, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(goData),
			});

			if(!response.ok) {
				console.error("There was some error");

				const data = await response.json();

				console.info(data);

				return false;
			} else {

				const data = await response.json();

				patientId = data['data']['id'];
			}	


			if(patientId) {

				headers = await getHeaders();

				URL = getURL("patients", patientId, type);

				const rr = await fetch(URL, {
					headers: headers,
					method: "POST",
				});
				
				if(rr.ok) {

					toast.dismiss();
					const dd = await rr.json();
					let link = dd['redirect']['url'];

					dismiss();
					navigate(link);


				} else {

					toast.update(currentToast.current, {
						...errorOpts,
						render: "There was some error while creating/modifying the patient record. Please try again or contact support@chiorscipt.ai if this error persists.",
					})

				}

				


			}

			

		} catch (err) {
			console.error(err);
			
			toast.update(currentToast.current, {
				...errorOpts,
				render: "There was some error while creating/modifying the patient record. Please try again or contact support@chiorscipt.ai if this error persists.",
			})
		} finally {
			setGroupDisabled(false);
		}
	};

	// console.log(children);
	return (
		<div className={`flex h-[100vh] w-full flex-row gap-5 bg-page p-6`}>
			{children}
			<dialog
				className={`modal z-[4] ${visible ? "modal-open" : ""}`}
				id="patient_modal"
			>
				<div className="modal-box bg-white rounded-lg w-[50%] max-w-5xl relative">
					<button
						className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
						onClick={(e) => groupDisabled ? e.preventDefault() : dismiss()}
					>
						✕
					</button>
					{groupDisabled && <Loading />}
					<h5 className={twMerge("font-inter", clsx({
						"hidden": groupDisabled,
					}))}>Create a new Patient</h5>
					<form
						onSubmit={handleSubmit(patientFormSubmit)}
						className={twMerge("flex flex-col gap-4 mt-4 w-full", {
							"hidden": groupDisabled
						})} id="create-patient"
					>
						<div className="flex flex-col gap-2 w-full">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="firstName"
							>
								First Name *
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									clsx({
										error: errors["first"],
									})
								)}
								type="text"
								id="firstName"
								placeholder="First Name"
								{...register("first", {
									required: "First name is required",
								})}
							/>
							{typeof errors["first"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["first"].message}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-2 w-full">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="lastName"
							>
								Last Name *
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									clsx({
										error: errors["last"],
									})
								)}
								type="text"
								id="lastName"
								placeholder="Last Name"
								{...register("last", {
									required: "Last name is required",
								})}
							/>
							{typeof errors["last"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["last"].message}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-2 w-full relative">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="dob"
							>
								Date of Birth *
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									"focus:border-primary cursor-pointer",
									clsx({
										error: errors["dob"],
									})
								)}
								type="text"
								id="dob"
								value={dt.toLocaleDateString("en-US", {
									month: "short",
									day: "numeric",
									year: "numeric",
								})}
								readOnly={true}
								placeholder="Date of Birth"
								{...register("dob", {
									required: "Date of Birth is required",
								})}
							/>
							{typeof errors["dob"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["don"].message}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-2 w-full relative">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="email"
							>
								Email *
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									clsx({
										error: errors["email"],
									})
								)}
								type="email"
								id="email"
								placeholder="example@example.com"
								{...register("email", {
									required: "Email Address is required",
								})}
							/>
							{typeof errors["email"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["email"].message}
								</p>
							)}
						</div>
						<div className="flex flex-col gap-2 w-full relative">
							<label
								className="font-inter text-font-200 text-[90%]"
								htmlFor="phno"
							>
								Phone
							</label>
							<input
								className={twMerge(
									INPUT_CLASS,
									clsx({
										error: errors["phno"],
									})
								)}
								type="tel"
								id="phno"
								placeholder="+1 102 405 4984"
								{...register("phno")}
							/>
							{typeof errors["phno"] !== "undefined" && (
								<p className="ml-1 text-[80%] leading-[0.9] font-semibold mt-[1.2px] text-red-500">
									{errors["phno"].message}
								</p>
							)}
						</div>
						<button className="rounded-[80px] h-[55px] w-full border-primary border-[1px] border-solid font-inter text-primary bg-white hover:bg-accent/65 duration-500 hover:text-font-600 hover:border-opacity-80 shadow-sm shadow-page hover:shadow-none text-[95%]">
							Save
						</button>
					</form>
				</div>

				<div
					className={twMerge(
						"hidden absolute top-[150px]",
						clsx({
							block: showCalendar,
						})
					)}
				>
					<Calendar
						className={`!border-none shadow-md shadow-black/40 rounded-md`}
						onChange={onChange}
						value={dt}
						maxDate={new Date()}
						
					/>
				</div>
			</dialog>
		</div>
	);
}
