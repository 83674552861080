import { createContext, useCallback, useContext, useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { useDashboard } from "../../Dashboard/useDashboard";
import { doc, getDocs, limit, onSnapshot, where, query, collection } from "firebase/firestore";
import { db } from "../../../util/firebase";
import { useParams } from "react-router-dom";
import { getDateFormat, getTimeFormat } from "../../../util/util";



const EncounterContext = createContext({
	encounterId: "",
	patient: null,
	encounter: null,
	key: null,
	type: null,
});

export function useEncounter() {
	return useContext(EncounterContext);
}

export default function EncounterProvider({ children }) {

	const [ loader, setLoader ] = useState(true);

	const [ patient, setPatient ] = useState(null);

	const [ encounter, setEncounter ] = useState(null);

	const [ lastEncounter, setLastEncounter ] = useState(false);

	const [ key, setKey ] = useState(null);

	const [ type, setType ] = useState(null);

	const { id: encounterId } = useParams();

	const { currentUser: {
		user, auth, details
	}, payload: {
		patients
	}, location: {
		navigate
	}, set: {
		title
	} } = useDashboard();
	
	title(patient ? `Encounter for ${patient?.first}` : "New Encounter");

	useEffect( () => {

		if( !user || !auth || !encounterId || typeof encounterId !== "string") return () => false;

		return onSnapshot(doc(db, "encounters", encounterId), (snapshot) => {

			if(!snapshot.exists()) {
				return false;
			}


			const data = snapshot.data({
				serverTimestamps: "estimate"
			});


			if(encounter === null) {
				setEncounter({
					...data,
					id: snapshot.id
				});
				const le = {
					...data,
					id: snapshot.id
				};
	
				const dt = new Date(le['createdAt'].seconds * 1000)
	
				le['date'] = getDateFormat(dt);
				le['time'] = getTimeFormat(dt);
				le['url'] = `/encounters/${doc.id}`;
				le['checkedBy'] = `Dr. ${details.displayName}`;

				setType(data['type']);
				
				setEncounter( (data) => ({
					...data,
					...le
				}));

			} else {

				setEncounter( (data) => ({
					...data
				}));

			}
			
		}, (error) => {
			console.error(error);
			navigate('/');
		})

	}, [user, auth, encounterId]);


	useEffect( () => {

		if(!patient || !encounter || lastEncounter !== false) return () => false;

		// TODO: Check form patient records, that can be done too

		if(patient && encounter && loader) {
			setLoader(false);
		}

		const q = query(collection(db, "encounters"), where("patient.id", "==", patient.id), where("uid", "==", user.uid)); // sort by createdAt "desc"

		getDocs(q).then( (res) => {
			
			// console.log(res.size);

			if(res.size === 1) {
				setLastEncounter(false);
			}

			const doc = Array.from(res.docs).filter( (item) => item.id !== encounterId)?.[0];

			// console.log(doc);

			if(doc && doc.exists()) {

				const le = {
					...doc.data(),
					id: doc.id
				};

				// console.log(le['createdAt']);
			
				const dt = new Date(le['createdAt'].seconds * 1000)
			
				le['date'] = getDateFormat(dt);
				le['time'] = getTimeFormat(dt);

				// console.log(le);

				le['url'] = `/encounters/${doc.id}`;
				le['checkedBy'] = `Dr. ${details.displayName}`;

				setLastEncounter((() => ({
					...le
				})));
			}

		}).catch( () => {

			setLastEncounter(false);

		})
		
		return () => false
	}, [patient, encounter]);



	useEffect( () => {

		if(encounter === null || patient !== null) return () => false;
		

		return onSnapshot(doc(db, "patients", encounter['patient']['id']), (snapshot) => {
			
			if(!snapshot.exists()) {
				navigate('/');
			}

			const data = snapshot.data({
				serverTimestamps: "estimate"
			});


			setPatient({
				...data,
				id: snapshot.id
			});

		}, (error) => {
			navigate('/')
		})


	}, [encounter])


	useEffect( () => {
		
		if(typeof details !== "object" && !("key" in details) || typeof details["key"] !== "object") return () => false;

		setKey(details["key"]);

	}, [details]);
	

	const value = {
		encounterId,
		patient,
		encounter,
		lastEncounter,
		type,
		key,
		location: {
			navigate,
		},
		currentUser: {
			user,
			auth,
			details
		},
	}


	return (
		<EncounterContext.Provider value={value}>
			 {(loader) ? 
			 <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col gap-y-3 items-center">
				<Loading classes={"w-[5rem]"} />
				<span className="text-primary">Please wait while we configure the application.</span> 
			 </div>
			 
			: children}
		</EncounterContext.Provider>
	)

}
