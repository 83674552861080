import { useEffect, useState, useContext, useReducer, createContext } from "react";
import { auth, db, analytics, messaging } from '../../../util/firebase';
import Snackbar from 'node-snackbar'
import { onMessage } from "firebase/messaging";

import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from 'react-router-dom';

import { setUserId } from "firebase/analytics";

import { useLocalStorage, useSessionStorage, useDocumentTitle } from "@uidotdev/usehooks";
import { collection, onSnapshot, query, where, doc, orderBy, getDocs, addDoc } from "firebase/firestore";

import Loading from "../../../components/Loading";
import useDevice from "../../../hooks/useDevice";
import { getJWT } from "../../../util/util";


const DashboardContext = createContext({ currentUser: {}, location: {
	navigate: (to, options = {} ) => { return; },
	currentPath: ""
}, pwa: {}, payload: { encounters: [], patients: [] }, set: {
	title: (t) =>{ return ; } 
} } );


// eslint-disable-next-line react-refresh/only-export-components
export function useDashboard() {
    return useContext(DashboardContext);
}


const reducer = (state, action) => {

    switch(action.type) {
        case 'NEW':
            return [...state, {
                ...action?.payload ?? {}
            }];

        case 'REMOVE': return state.filter((resource) => resource.id !== action.payload.id);

        case 'UPDATE': 
            return state.map( resource => {
                if(resource.id === action.payload.id) {
                    return action?.payload;
                } else {
                    return resource;
                }
            });

        case 'default':
            return state;
    }


}


const greet = () => {
	let hour = new Date().getHours();
	let greeting;
	if (hour < 12) {
		greeting = "Good Morning";
	} else if (hour < 18) {
		greeting = "Good Afternoon";
	} else {
		greeting = "Good Evening";
	}
}


// eslint-disable-next-line react/prop-types
export default function DashboardProvider({ children }) {

    const [ user, loading,  ] = useAuthState(auth)
    const [ loader, setLoader ] = useState(true);
    const [ pwa, setPWA ] = useState({
        deferredPrompt: null,
        promote: false,
    })

    const [ encounters, dispatchEncounter ] = useReducer(reducer, []);
    const [ patients, dispatchPatient ] = useReducer(reducer, []);
    
    const [ pwaDismiss, setPWADismiss ] = useLocalStorage("pwadismiss", "no");
    const [ apzi, setApzi ] = useState(false);

	const [ isPatientModalVisible, setIsPatientModalVisible ] = useState(false);
	const [ patientModalType, setPatientModalType ] = useState(null);

	const togglePatientModal = (mode, type) => {
		setPatientModalType(type);
		setIsPatientModalVisible(mode);
	}

	const [ userDetails, setUserDetails ] = useState({
        verified: null,
        approved: true,
        displayName: null,
        stable: false,
    });

    useEffect( () => {
        if(user !== null && db !== null) {
            // console.log(user.uid);
            return onSnapshot(query(collection(db, 'encounters'), where("uid", "==", user.uid), /* orderBy("createdAt", "desc") */), (snapshot) => {
                // console.log(snapshot)
                snapshot.docChanges().forEach( (change) => {
                    let type;
                    if(change.type === 'added') {
						type = 'NEW';
					}else if(change.type === 'modified') {
						type = 'UPDATE'
					}else if( change.type === 'removed' ) {
						type = 'REMOVE'
					}

                    // if(type)

					let payload = change.doc.data({
						serverTimestamps: 'estimate'
					});
                    
					let id = change.doc.id;

                    if(payload && typeof type === 'string') {

						dispatchEncounter( {type, payload: {id, ...payload}} );

                    }
                });
            })
        }
    }, [user, db]);


    useEffect( () => {
        if(user !== null && db !== null) {
            // console.log(user.uid);
            return onSnapshot(query(collection(db, 'patients'), where("uid", "==", user.uid), where("trash", "==", false), /* orderBy("createdAt", "desc") */), (snapshot) => {
                // console.log(snapshot)
                snapshot.docChanges().forEach( (change) => {
					
                    let type;
                    if(change.type === 'added') {
						type = 'NEW';
					}else if(change.type === 'modified') {
						type = 'UPDATE'
					}else if( change.type === 'removed' ) {
						type = 'REMOVE'
					}

					let payload = change.doc.data({
						serverTimestamps: 'estimate'
					});
                    
					let id = change.doc.id;

					let patient = (patients ?? []).filter((item) => item?.id === id);

					if(patient.length > 0 && type === "NEW") {
						type = "UPDATE";
					}

                    if(payload && typeof type === 'string') {

						dispatchPatient( {type, payload: {id, ...payload}} );

                    }
                });
            })
        }
    }, [user, db]);

	// console.log(patients, encounters);


    useEffect( () => {

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            
            setPWA(() => ({
                deferredPrompt: e,
                promote: true,
            }));

            // console.log(`'beforeinstallprompt' event was fired.`);
        });

        window.addEventListener('appinstalled', () => {
            
            setPWA(() => ({
                deferredPrompt: null,
                promote: false,
            }));

            // console.log('PWA was installed');
        });

    }, []);

    useEffect( () => {

        if(!loading) {
            if(!user) {

                let next = encodeURIComponent(window.location.pathname.substring(1));

                if(next.length === 0) {	
                    navigate(`/welcome`);
                } else {
                    navigate(`/login?next=${next}`);
                }

            } else {
                setUserId(analytics, user.uid);

                return onSnapshot(doc(db, "users", user.uid), async (snapshot) => {
                    if(!snapshot.exists()) {
                        return false;
                    }

                    const data = snapshot.data({
                        serverTimestamps: "estimate"
                    });

                    if(!apzi) {
                        // console.log(data.createdAt, new Date(data.createdAt.seconds))
                        window.appziSettings = {
                            'userId': data.uid,
                            data: {
                                'uid': data.uid,
                                'premium': data.subscribed,
                                'role': data.role,
                                'email': data.email,
                            },
                        }

                        setApzi(true);
                    }

                    const idToken = await getJWT(false);

					// console.log({
					// 	jwt: idToken
					// })

                    setUserDetails({
                        ...data,
                        idToken,
                        stable: true,
                    });

                    if(loader) {
                        setLoader(false);
                    }

                }, (err) => console.error("Something went wrong", err));
            }
        }

    }, [loading, user]);

    async function installApp() {
        if(pwa && pwa.promote && pwa.deferredPrompt) {
            let { deferredPrompt } = pwa;
            
            setPWA((prev) => ({
                ...prev,
                promote: false,
            }));

            deferredPrompt.prompt();
            
            // Wait for the user to respond to the prompt
            const { outcome } = await deferredPrompt.userChoice;

            // console.log({
            //     outcome
            // });

            console.log(`User response to the install prompt: ${outcome}`);
            
            // We've used the prompt, and can't use it again, throw it away
            // DO NOT NEED TO DO THIS AS THIS IS REACT.JS
            deferredPrompt = null;
        }
    }


    useEffect( () => {
        window.addEventListener("swupdatefound", () => {
            Snackbar.show({
                actionText: "Reload",
                onActionClick: () => window.location.reload(),
                "text": "ChiroScript.ai just got updated. Please reload to quickly get the latest version.",
            })
        })
    }, []);


    useEffect( () => {
        return onMessage(messaging, async (payload) => {

            console.log("Frontend Message recieved ", payload);

            const notificationTitle = payload.data.title;
            const options = JSON.parse(payload.data.options);
            const notificationOptions = {
                ...options,
                vibrate: (new Boolean(options.vibrate)).valueOf(),
                renotify: (new Boolean(options.renotify)).valueOf(),
                icon: "/assets/icon-bg-72.png",
            };

            console.log("Payload structured ", notificationOptions);

        })
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;
	const device = useDevice();

    const value = {
        currentUser: {
            user,
            details: userDetails,
            auth
        },
        location: {
            navigate,
            currentPath,
        },
        pwa: {
			isDesktop: device === "desktop",
			isTablet: device === "tablet",
			isPhone: device === "phone",
            installApp,
            dismiss: () => setPWADismiss("yes"),
            show: pwaDismiss === "no",
            ...pwa,
        },
        payload: {
            encounters,
            patients,
        },
		set: {
			title: (t) => useDocumentTitle(`${t} | ChiroScript.ai`),
			patient: {
				visible: isPatientModalVisible,
				type: patientModalType,
				dismiss: () => togglePatientModal(false, null),
				show: (type) => togglePatientModal(true, type),
			}
		}
    }

    return (
        <DashboardContext.Provider value={value}>
            {(loader || loading) ? <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col gap-y-3 items-center">
				<Loading classes={"w-[5rem]"} />
				<span className="text-primary">Please wait while we prepare the dashboard</span> 
			 </div> : children}
        </DashboardContext.Provider>
    )
    
}
