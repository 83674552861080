export const PATIENT_PATTERN = /^\/patient\/(.*)$/
export const ENCOUNTERS_PATTERN = /^\/encounters\/(.*)$/

export const DATE_FORMAT_PARAMETER = { day: "numeric", month: "short", year: "numeric", weekday: "short", timeZone: undefined}

export const ENCOUNTER_NOTE = "notes";
export const ENCOUNTER_QME = "qme";

export const VAPID_KEY = "BAKkQ4w0g830tiOWn8dQKitR6uB96B-eC6X8NjZCtEFjcoWc8RuaN4euybRPrrulMWwL2W28KZotVtRXNKlo_VM"

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


